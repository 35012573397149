export enum Environment {
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Production = 'Production',
    Test = 'Test',
}

export enum SgConnectEnvironment {
    Homologation = 'homologation',
    Production = 'production',
}

export const getEnvironment = () => {
    let environment = Environment.Production;

    if (typeof window === 'undefined') {
        environment = Environment.Test;
    } else if (window.location.hostname === 'local.fr.world.socgen'
        || window.location.hostname === 'localhost') {
        environment = Environment.Local;
    } else if (window.location.hostname === 'picture-collection-dev.sgmarkets.com'
        || window.location.hostname === 'picture-collection-dev.azureedge.net') {
        environment = Environment.Development;
    } else if (window.location.hostname === 'picture-collection-uat.sgmarkets.com'
        || window.location.hostname === 'picture-collection-uat.azureedge.net') {
        environment = Environment.Homologation;
    } else if (window.location.hostname === 'picture-collection.sgmarkets.com'
        || window.location.hostname === 'picture-collection-prd.azureedge.net'
        || window.location.hostname === 'picture-collection-preview.sgmarkets.com'
        || window.location.hostname === 'picture-collection-preview.azureedge.net') {
        environment = Environment.Production;
    }

    return environment;
};

interface IConfiguration {
    baseApiUrls: {
        pictureCollection: string;
    }
    requestAccessUrl: string;
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    sgConnectEnvironment: SgConnectEnvironment;
    widgetCdnBaseUrl: string;
    sharedSgMarketsBaseUrl: string;
    sgMonitoringRumUrl: string;
}

const defaultConfiguration = {
    baseApiUrls: {
        pictureCollection: 'https://pretrade-api-picture-collection-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
    },
    requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://local.fr.world.socgen:5252',
    clientId: 'd0ea64ea-2157-4f20-95f8-a68b8e1a4c18',
    authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    scope: [
        'mail',
        'openid',
        'profile',
        'api.pretrade-picture-collection.v1',
        'api.pretrade-picture-collection.front.v1',
    ].join(' '),
    sgConnectEnvironment: SgConnectEnvironment.Homologation,
    widgetCdnBaseUrl: 'https://sgwt-widgets-dev.sgmarkets.com',
    sharedSgMarketsBaseUrl: 'https://shared-uat.sgmarkets.com',
    sgMonitoringRumUrl: 'https://insight-realm-apm-hom.fleet.uat.sgmonitoring.dev.euw.gbis.sg-azure.com',
};

export const configurationPerEnvironment: Record<Environment, IConfiguration> = {
    [Environment.Local]: {
        ...defaultConfiguration,
    },
    [Environment.Development]: {
        ...defaultConfiguration,
        baseApiUrls: {
            pictureCollection: 'https://pretrade-api-picture-collection-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
        },
        requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://picture-collection-dev.sgmarkets.com',
    },
    [Environment.Homologation]: {
        ...defaultConfiguration,
        baseApiUrls: {
            pictureCollection: 'https://pretrade-api-picture-collection-uat.sgmpinsight.dev.euw.gbis.sg-azure.com',
        },
        requestAccessUrl: 'https://shared-uat.sgmarkets.com/unauthorized/?redirectUri=https://picture-collection-uat.sgmarkets.com',
    },
    [Environment.Production]: {
        ...defaultConfiguration,
        baseApiUrls: {
            pictureCollection: 'https://pretrade-api-picture-collection-prd.sgmpinsight.prd.euw.gbis.sg-azure.com',
        },
        requestAccessUrl: 'https://shared.sgmarkets.com/unauthorized/?redirectUri=https://insight.sgmarkets.com',
        clientId: '0fe1e9b5-a3f4-432f-8061-c07c130d06fe',
        authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect/oauth2/authorize',
        sgConnectEnvironment: SgConnectEnvironment.Production,
        widgetCdnBaseUrl: 'https://sgwt-cdn-widgets.sgmarkets.com',
        sharedSgMarketsBaseUrl: 'https://shared.sgmarkets.com/',
        sgMonitoringRumUrl: 'https://t-monitoring-rum.sgmarkets.com/rum/540a239031b64d7fbe34fe54fef041db',
    },
    [Environment.Test]: {
        ...defaultConfiguration,
    },
};

export const configuration = configurationPerEnvironment[getEnvironment()];
